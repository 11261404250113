import styled from "@emotion/styled"
import React from "react"
import { mobile } from "../responsive"
import { useSelector } from "react-redux"
import { collection, getDocs, getDoc, doc } from "firebase/firestore"
import { FirebaseContext } from "../App"
import { useContext } from "react"
import { useEffect, useState } from "react"
import Product from "../components/Product"

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
`

export const Logo = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  padding-top: 10px;
  ${mobile({ fontSize: "24px" })};
`

const IntroContainer = styled.div`
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;

  p {
    ${mobile({ fontSize: "20px" })};
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
  }
`

const ProductContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px 200px;
  p {
    ${mobile({ fontSize: "20px" })};
    margin-bottom: 20px;
    font-size: 26px;
    text-align: center;
  }
`

const ChoosePruduct = props => {
  if (!props.products) return "Nemáš přístup."
  const warehouse = { ...props.warehouse }
  const prices = { ...props.prices }
  return (
    <ProductContainer>
      {props.products.map(({ id, ...productData }) => {
        const { SKU, EAN, name, ...productWarehouseDivide } = productData
        let min = Math.min(warehouse["ruler"], warehouse["glue"], warehouse["box"], warehouse["envelope"], warehouse["brush"], warehouse["scissors"])
        const price =
          prices["ruler"] +
          prices["glue"] +
          prices["box"] +
          prices["envelope"] +
          prices["brush"] +
          prices["scissors"] +
          prices["instructions"] +
          (productData["pink"] || 0) * (prices["pink"] + prices["toner"]) +
          (productData["orange"] || 0) * (prices["orange"] + prices["toner"]) +
          (productData["white"] || 0) * (prices["white"] + prices["toner"]) +
          (productData["grey"] || 0) * (prices["grey"] + prices["toner"]) +
          (productData["black"] || 0) * (prices["black"] + prices["toner"])
        for (const [key, value] of Object.entries(productWarehouseDivide)) {
          min = Math.min(min, Math.floor(warehouse[key] / value))
        }
        return (
          <div key={id}>
            <Product product={productData} />
            <p>Max {min}ks</p>
            <p>Výrobní cena: {Math.ceil(price)} CZK</p>
          </div>
        )
      })}
    </ProductContainer>
  )
}

const Home = () => {
  const { user } = useSelector(state => state.user) || {}
  const { db } = useContext(FirebaseContext)

  const [products, setProducts] = useState(null)
  const [warehouse, setWarehouse] = useState(null)
  const [prices, setPrices] = useState(null)

  const fetchData = async () => {
    try {
      const docRef = doc(db, "warehouse", "mainwarehouse")
      const docSnap = await getDoc(docRef)
      const warehouse = docSnap.data()
      setWarehouse(warehouse)

      const docRefPrices = doc(db, "warehouse", "prices")
      const docSnapPrices = await getDoc(docRefPrices)
      const prices = docSnapPrices.data()
      setPrices(prices)

      const result = await getDocs(collection(db, "products"))
      const data = []
      {
        result.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })
      }
      setProducts(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  const email = user?.user?.email
  const loggedIn = email === "papermask.info@gmail.com"
  const text = loggedIn ? "Vyber produkt k odbavení" : !email ? "Přihlašování..." : "Nemáš právo používat administraci."

  return (
    <MainContainer>
      <Logo>VYBER PRODUKT K ODBAVENÍ</Logo>
      <IntroContainer>
        <p>{text}</p>
      </IntroContainer>
      {/*loggedIn && */ <ChoosePruduct products={products} warehouse={warehouse} prices={prices} />}
    </MainContainer>
  )
}

export default Home
