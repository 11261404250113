import React from "react"
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled"
import { mobile } from "../responsive"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import Product from "../components/Product"
import { Button } from "../components/Button"
import { useContext } from "react"
import { doc, setDoc, getDoc } from "firebase/firestore"
import { FirebaseContext } from "../App"
import { PDFDocument } from "pdf-lib"
import download from "downloadjs"

function getNextDayFormatted() {
  const today = new Date()
  const tomorrow = new Date(today)

  // Add one day
  tomorrow.setDate(tomorrow.getDate() + 1)

  const day = tomorrow.getDate().toString().padStart(2, "0")
  const month = (tomorrow.getMonth() + 1).toString().padStart(2, "0") // +1 because months are 0-indexed
  const year = tomorrow.getFullYear()

  return `${day}.${month}.${year}`
}

const emailBodyToClip = async count => {
  await navigator.clipboard.writeText(
    `Dobrý den,\n\nobjednávám tímto svoz ${count} balíků na ${getNextDayFormatted()}.\nV příloze přikládám vyplněný formulář a prosím vás o info o čase svozu.\n\nS pozdravem\nVolf`
  )
}

const Home = () => {
  const { db } = useContext(FirebaseContext)
  const navigate = useNavigate()
  const { user } = useSelector(state => state.user) || {}
  const email = user?.user?.email
  const loggedIn = email === "papermask.info@gmail.com"
  const { pathname } = useLocation()
  const pathArray = pathname.split("/")
  const product = JSON.parse(decodeURIComponent(atob(pathArray[pathArray.length - 1])))
  const { SKU, EAN, name, ...productWarehouseSubtract } = product

  const handleDone = async () => {
    const docRef = doc(db, "warehouse", "mainwarehouse")
    const docSnap = await getDoc(docRef)
    const warehouse = docSnap.data()
    for (const [key, value] of Object.entries(productWarehouseSubtract)) {
      warehouse[key] -= value
    }
    warehouse["ruler"] -= 1
    warehouse["glue"] -= 1
    warehouse["box"] -= 1
    warehouse["envelope"] -= 1
    warehouse["brush"] -= 1
    warehouse["scissors"] -= 1
    await setDoc(doc(db, "warehouse", "mainwarehouse"), warehouse)
    await new Promise(resolve => setTimeout(resolve, 1000))
    navigate("/warehouse")
  }

  const getMaskPrintFiles = () => {
    const link = (name, filePath) => {
      const commonPath = "../print/"
      return { name, path: `${commonPath}${filePath}.pdf` }
    }
    switch (SKU) {
      case "PMW":
      case "PMF":
        return [
          link("Tmavá část", "wolf_or_fox/icon_low_visibility/orange_or_grey_fur"),
          link("Bílá část", "wolf_or_fox/icon_low_visibility/white_fur"),
          link("Nosy", "wolf_or_fox/Noses")
        ]

      case "PMCG":
      case "PMCW":
        return [link("Vše stejnobarevně", "cat_samecolor/icon_low_visibility/cat_samecolor")]

      case "PMUW":
      case "PMUP":
        return [link("Vše stejnobarevně", "unicorn_samecolor/icon_low_visibility/unicorn_samecolor_horn_last_page")]

      case "PMRW":
      case "PMRG":
        return [link("Vše stejnobarevně", "rabbit_samecolor/icon_low_visibility/rabbit_samecolor")]

      case "PMRE":
        return [
          link("Bílá část ", "rabbit_multicolor/icon_low_visibility/white_fur"),
          link("Šedá část ", "rabbit_multicolor/icon_low_visibility/grey_fur_last_two_pages_ears"),
          link("Nosy (zatím nepodporované, najdi vytisklý) ", "not_supported_yet")
        ]

      case "PMP":
        return [link("Černá část", "panda/icon_low_visibility/black_fur"), link("Bílá část", "panda/icon_low_visibility/white_fur")]
    }
  }

  /*if (!loggedIn)
    return (
      <MainContainer>
        <Logo>POLYPAPER ADMINISTRACE</Logo>
        <IntroContainer>
          <P>Nemáš právo používat administraci.</P>
        </IntroContainer>
      </MainContainer>
    )*/

  return (
    <MainContainer>
      <Logo>ODBAVENÍ OBJEDNÁVKY</Logo>
      <IntroContainer>
        <Product product={product} />
        <Step>
          <Circle>1</Circle>
          <P>Vytiskni šablony na čtvrtky v režimu "Actual Size":</P>

          {getMaskPrintFiles().map(({ name, path }) => (
            <SmallP>
              <a href={path} target="_blank" rel="noreferrer" key={name}>
                {name}
              </a>{" "}
            </SmallP>
          ))}
        </Step>
        <Step>
          <Circle>2</Circle>
          <P>
            <a href={"../print/navod.pdf"} target="_blank" rel="noreferrer">
              Vytiskni návod na foto papír v režimu "Actual Size"
            </a>
          </P>
        </Step>
        <Step>
          <Circle>3</Circle>
          <P>Vlož do krabičky</P>
          <SmallP>(1) šablony (2) návod (3) nůžky (4) pravítko (5) šteček (6) lepidlo (7) výplňový materiál</SmallP>
        </Step>
        <Step>
          <Circle>4</Circle>
          <P>
            <a href="https://trade.alza.cz/app/orders?q=eyIkY291bnQiOnRydWUsInBhZ2UiOjEsInBhZ2VTaXplIjoyMCwic29ydERlc2MiOnRydWUsInNvcnRLZXkiOjAsInNlYXJjaEluIjoxfQ%3D%3D">
              Alza Trade
            </a>
          </P>
          <SmallP>Vytiskni EAN na krabičku a poštovní štítek na obálku. Potvrď odeslání.</SmallP>
        </Step>
        <Step>
          <Circle>5</Circle>
          <P>Objednej svoz na objednavky@dpd.cz</P>

          <Button
            onClick={async () => {
              const existingPdfBytes = await fetch("../dpd.pdf").then(res => res.arrayBuffer())
              const pdfDoc = await PDFDocument.load(existingPdfBytes)
              const pages = pdfDoc.getPages()
              const firstPage = pages[0]
              firstPage.drawText(getNextDayFormatted(), {
                x: 112,
                y: 310,
                size: 10
              })
              const pdfBytes = await pdfDoc.save()
              download(pdfBytes, "pdf-lib_form_creation_example.pdf", "application/pdf")
            }}
          >
            Stáhni DPD formulář a přilož ho do emailu
          </Button>
          <br />
          <Button
            onClick={async () => {
              await emailBodyToClip(1)
              window.open("https://mail.google.com/mail/u/1/#sent?compose=new", "_blank")
            }}
          >
            Zkopírovat text emailu do schránky
          </Button>
        </Step>
        <Step>
          <Circle>6</Circle>
          <Button onClick={handleDone}>Hotovo (odečte ze skladových zásob)</Button>
        </Step>
      </IntroContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
`

export const Logo = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  padding-top: 10px;
  ${mobile({ fontSize: "24px" })};
`

const P = styled.p`
  ${mobile({ fontSize: "20px" })};
  margin-bottom: 20px;
  font-size: 30px;
  text-align: center;
`
const SmallP = styled.p`
  ${mobile({ fontSize: "16px" })};
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
`

const BigText = styled.a`
  font-size: 30px;
`

const SmallText = styled.a`
  font-size: 24px;
`

const IntroContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;

  a,
  a:visited {
    color: black;
    ${mobile({ fontSize: "20px" })};
    text-align: center;
    cursor: pointer;
  }
`

const Circle = styled.div`
  width: 100px; /* Size of the circle */
  height: 100px; /* Size of the circle */
  background-color: #808080; /* Circle color */
  border-radius: 50%; /* Rounded corners to make it a circle */
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

export default Home
